import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './App.css';
import logo from './leon logo highres.jpg';

function App() {
  const [dotCount, setDotCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDotCount((prev) => (prev === 3 ? 0 : prev + 1));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-100">
      <img src={logo} alt="Leon Logo" className="logo" />
      <div className="text-container">
        <span>Coming Soon</span>
        <span className="dots">
          <motion.span
            className="dot"
            animate={{ opacity: dotCount > 0 ? 1 : 0 }}
          >.</motion.span>
          <motion.span
            className="dot"
            animate={{ opacity: dotCount > 1 ? 1 : 0 }}
          >.</motion.span>
          <motion.span
            className="dot"
            animate={{ opacity: dotCount > 2 ? 1 : 0 }}
          >.</motion.span>
        </span>
      </div>
    </div>
  );
}

export default App;
